import React, { useState } from 'react';

export const menuContext = React.createContext();

const Provider = props => {
  const [menuOpen, setMenu] = useState(false);

  return (
    <menuContext.Provider value={{
      menuOpen,
      toggleMenu: () => setMenu(!menuOpen),
      closeMenu: () => setMenu(false)
          }}>
      {props.children}
    </menuContext.Provider>
  )
};

const ProviderWrapper =({ element }) => (
    <Provider>
      {element}
    </Provider>
  );

  export default ProviderWrapper